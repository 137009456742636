@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;400;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'JetBrains Mono', source-code-pro, Consolas, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

pre, code {
  font-family: 'JetBrains Mono', source-code-pro, Consolas, monospace;
}
